<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="24"
    viewBox="0 0 56 24"
    fill="none"
  >
    <path
      d="M16 7H40M16 8H40M19.6923 15H27.0769M19.6923 18H23.3846M18.7692 22H37.2308C37.9652 22 38.6696 21.6839 39.1889 21.1213C39.7082 20.5587 40 19.7956 40 19V5C40 4.20435 39.7082 3.44129 39.1889 2.87868C38.6696 2.31607 37.9652 2 37.2308 2H18.7692C18.0348 2 17.3304 2.31607 16.8111 2.87868C16.2918 3.44129 16 4.20435 16 5V19C16 19.7956 16.2918 20.5587 16.8111 21.1213C17.3304 21.6839 18.0348 22 18.7692 22Z"
      stroke="#262729"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
<script lang="ts">
export default defineComponent({
  name: 'IconNavPay',
})
</script>
